import 'react-datepicker/dist/react-datepicker.css';

import cx from 'classnames';
import { Carousel } from 'components/Carousel';
import { ConnectButton, ConnectButtonText } from 'components/ConnectButton/ConnectButton';
import { FormattedValue } from 'components/FormattedValue/FormattedValue';
import { DashboardLayout } from 'components/layouts/DashboardLayout';
import { RewardsGroup } from 'components/RewardsGroup';
import { Settings } from 'components/Settings/Settings';
import { TextInput, useTextInputContext } from 'components/TextInput';
import { TextInputName } from 'components/TextInput/types';
import TopCards from 'components/TopCards';
import { useAppSettings } from 'context/AppSettingsProvider';
import { useGlobalValues } from 'context/ContractValuesProvider';
import { disabledConfig, useModal } from 'context/ModalContext';
import { useWeb3Context } from 'context/Web3Provider';
import { Contract } from 'helpers/addresses';
import { addCollateral } from 'helpers/collateral';
import { isProd } from 'helpers/env';
import { Action, useBankxActionModalConfig } from 'helpers/getPreferredBankxAction';
import { isValidInput } from 'helpers/input';
import { TokenLabels } from 'helpers/labels';
import { redeemLiquidity } from 'helpers/liquidity';
import { Logger } from 'helpers/logging';
import { CustomLoadingMessage, useCustomLoadingMessage } from 'hooks/useCustomLoadingMessages';
import { useWallet } from 'hooks/useWallet';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import Styles from './styles.module.scss';

export function LiquidityPool(): React.ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { state: locationState } = useLocation() as any;

    function isPageDisabled(): boolean {
        return true || isProd();
    }

    const isDisabled = isPageDisabled();
    const { getThemedClass: tcx } = useAppSettings();
    const [targetPool, setTargetPool] = useState(locationState?.targetPool || Contract.XSDPool);

    const globalValues = useGlobalValues();
    const walletValues = globalValues.walletValues;
    const { priceCheckBlockDelay, tokenLabel } = useWallet();

    const { useInputState } = useTextInputContext();
    const [amountToSell, setAmountToSell] = useInputState(TextInputName.Liquidity);

    const { contracts, getProvider } = useWeb3Context();
    const {
        routerContract,
        bankxPoolContract,
        xsdPoolContract,
        pidContract,
        proxyContract,
        rewardsContract,
    } = contracts;
    const provider = getProvider();

    const isBankxSelected = targetPool === Contract.BankXPool;
    const withdrawValues = isBankxSelected ? walletValues.rewards.bankx.items : walletValues.rewards.xsd.items;
    const withdrawSummary = isBankxSelected ? walletValues.rewards.bankx.summary : walletValues.rewards.xsd.summary;
    const liquidityValues = isBankxSelected ? globalValues.bankxPoolLiquidity : globalValues.xsdPoolLiquidity;
    const targetPoolAddress = isBankxSelected ? bankxPoolContract.address : xsdPoolContract.address;

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setAmountToSell(e.target.value);
    }

    function handleMaxBtnClick(): void {
        const max = Math.min(Number(walletValues.maxButtonBalance), Number(liquidityValues.maxContributionInEth));
        setAmountToSell(String(max.toFixed(5)));
    }

    const {
        isLoading: isWithdrawLoading,
        message: withdrawLoadingMessage,
        next: goToNextWithdrawLoadingMessage,
        reset: resetWithdrawLoadingMessages,
    } = useCustomLoadingMessage();

    async function handleWithdrawClick(): Promise<void>  {
        try {
            resetWithdrawLoadingMessages(3);
            await redeemLiquidity({
                routerContract,
                provider,
                walletAddress: walletValues.address,
                targetContractAddress: targetPoolAddress,
                goToNextLoadingMessage: goToNextWithdrawLoadingMessage,
                pidContract,
                proxyContract,
                chain: globalValues.chain,
                blockDelay: priceCheckBlockDelay,
                rewardManager: rewardsContract,
                priceCheckBlockDelay,
            });
        } catch(e) {
            Logger.error(e);
            throw e;
        }
    }

    const {
        isLoading: isLiquidityLoading,
        message: liquidityLoadingMessage,
        next: goToNextLiquidityLoadingMessage,
        reset: resetLiquidityLoadingMessages,
    } = useCustomLoadingMessage();
    async function onSellLiquidity(): Promise<void> {
        try {
            resetLiquidityLoadingMessages(3);
            await addCollateral({
                inputAmount: amountToSell,
                routerContract,
                provider,
                targetPoolAddress,
                walletAddress: walletValues.address,
                goToNextLoadingMessage: goToNextLiquidityLoadingMessage,
                customLoadingMessage: CustomLoadingMessage.Liquidity,
                proxyContract,
                pidContract,
                priceCheckBlockDelay,
                chain: globalValues.chain,
            });
        } catch(e) {
            Logger.error(e);
            throw e;
        }
    }

    const [selectedWithdrawal, setSelectedWithdrawal] = useState(withdrawValues[0]);

    function onCarouselChange(item: typeof withdrawValues[0]): void {
        setSelectedWithdrawal(item);
    }

    useEffect((): void => {
        setSelectedWithdrawal(withdrawValues[0]);
    }, [withdrawValues]);

    const hasValidInput = isValidInput(amountToSell);
    const limitMessage = function(): string {
        const isBalanceExceeded = Number(amountToSell) > Number(walletValues.balance);
        const isLimitExceeded = Number(amountToSell) * Number(globalValues.ethPriceInUsd) > Number(liquidityValues.maxContribution);

        if (hasValidInput && isBalanceExceeded) {
            return ConnectButtonText.NotEnoughBalance;
        }
        if (hasValidInput && isLimitExceeded) {
            return ConnectButtonText.MaxAmountExceeded;
        }
        return ConnectButtonText.Empty;
    }();

    // modal logic
    const modalConfig = useBankxActionModalConfig(globalValues.bankxAction);
    const { triggerModal } = useModal();

    useEffect(() => {
        triggerModal(modalConfig);
    }, [triggerModal, modalConfig]);

    useEffect(() => {
        if (isDisabled) {
            triggerModal({
                ...disabledConfig,
                name: 'liquidity-disabled',
            });
        }
    }, [triggerModal, isDisabled]);

    // disable logic
    const allowedActions = new Set([Action.BankxLiquidity, Action.XsdLiquidity]);
    const isSellLiquidityDisabled = !hasValidInput || limitMessage.length > 0 || !allowedActions.has(globalValues.bankxAction?.action as Action);

    // most rewards label
    const mostRewardsLabel = useMemo(() => {
        switch (globalValues.bankxAction?.action) {
            case Action.BankxLiquidity:
                return 'BankX pool gives most reward.';
            case Action.XsdLiquidity:
                return 'XSD pool gives most reward.';
            default:
                return '';
        }
    }, [globalValues.bankxAction]);

    const callToAction = (
        <>
            <Settings title="Sell Liquidity For Rewards" className={Styles.header}/>
            <div className={cx(Styles.toggle, 'mb-3')}>
                <button
                    onClick={() => setTargetPool(Contract.XSDPool)}
                    className={cx('button-tab button-tab-sm mr-2', { [tcx('button-tab-active')]: targetPool === Contract.XSDPool })}
                    style={{ marginRight: '0px' }}
                >
                    {`${tokenLabel}/${TokenLabels.Xsd}`}
                </button>
                <button
                    onClick={() => setTargetPool(Contract.BankXPool)}
                    className={cx('button-tab button-tab-sm', { [tcx('button-tab-active')]: targetPool === Contract.BankXPool })}
                >
                    {`${tokenLabel}/${TokenLabels.Bankx}`}
                </button>
                <span className={Styles.bankxRewards}>{mostRewardsLabel}</span>
            </div>
            <div className="wc-body">
                <div className="form-group with-max-btn">
                    <TextInput
                        className={cx(tcx('form-control'), 'with-max-btn')}
                        name={TextInputName.Liquidity}
                        onChange={handleInputChange}
                        placeholder={`Amount of ${tokenLabel}`}
                        type="number"
                        value={amountToSell}
                        disabled={isLiquidityLoading || isWithdrawLoading || isDisabled}
                    />
                    <button
                        onClick={handleMaxBtnClick}
                        className="button-1 max-btn"
                        disabled={walletValues.isLoading || isDisabled}
                    >
                        MAX
                    </button>
                </div>
                <div
                    className={tcx('side-info-box')}
                    style={{ marginBottom: '0' }}
                >
                    <ul>
                        <li>
                            <label>{tokenLabel} Available:</label>
                            <FormattedValue value={walletValues.balance} isLoading={isLiquidityLoading} precision={4}/>
                        </li>
                        <li>
                            <label>{tokenLabel} in USD:</label>
                            <FormattedValue
                                isCurrency={true}
                                isLoading={isLiquidityLoading}
                                value={String(Number(amountToSell) * Number(globalValues.ethPriceInUsd))}
                            />
                        </li>
                        <li>
                            <label>Principal and Rewards in USD:</label>
                            <FormattedValue
                                isCurrency={true}
                                isLoading={isLiquidityLoading}
                                value={String(Number(amountToSell) * Number(globalValues.ethPriceInUsd) * (1 + liquidityValues.totalRoi))}
                            />
                        </li>
                    </ul>
                    <ConnectButton
                        disabled={isSellLiquidityDisabled || isWithdrawLoading || isDisabled}
                        className={cx('mt-2', tcx('button-1'))}
                        onClick={onSellLiquidity}
                        onDone={resetLiquidityLoadingMessages}
                        customLoadingMessage={liquidityLoadingMessage}
                        isLimitExceeded={limitMessage.length > 0}
                        limitMessage={limitMessage}
                    >
                        Sell Liquidity
                    </ConnectButton>
                </div>
            </div>
        </>
    );

    const withdraw = (
        <>
            <div className={cx('wc-title', Styles.withdrawHeader)}>
                <h3>Withdraw Rewards</h3>
            </div>
            <div className={cx(Styles.withdrawContent, 'wc-body mb-3')}>
                <Carousel
                    items={withdrawValues}
                    onChange={onCarouselChange}
                >
                    {(item): React.ReactElement => {
                        return (
                            <div className="item">
                                <div className={tcx('si-list')}>
                                    <ul>
                                        <li>
                                            <b>BankX (in USD):</b>
                                            <FormattedValue value={item.bankx} isCurrency isLoading={isWithdrawLoading} disableTooltip/>
                                        </li>
                                        <li>
                                            <b>XSD (in USD):</b>
                                            <FormattedValue value={item.xsd} isCurrency isLoading={isWithdrawLoading} disableTooltip/>
                                        </li>
                                        <li>
                                            <b>Withdrawal Date:</b>
                                            <span>{item.vestingDate}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        );
                    }}
                </Carousel>
            </div>
            <ConnectButton
                disabled={!selectedWithdrawal?.isVested || isLiquidityLoading || isDisabled}
                className={cx(Styles.withdrawButton,  'withdraw-margin', tcx('button-1'))}
                onClick={handleWithdrawClick}
                onDone={resetWithdrawLoadingMessages}
                customLoadingMessage={withdrawLoadingMessage}
            >
                Withdraw
            </ConnectButton>
        </>
    );

    const summary = (
        <div className="px-2">
            <div
                className={cx(tcx('side-info-box'), tcx('collateral-box'), 'dark-gray-bg')}
                style={{ 'marginBottom': '0' }}
            >
                <ul>
                    <li>
                        <label>Total BankX At Maturity (USD):</label>
                        <FormattedValue value={withdrawSummary.bankxTotal} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                    <li>
                        <label>Total XSD at Maturity (USD):</label>
                        <FormattedValue value={withdrawSummary.xsdTotal} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                    <li>
                        <label>BankX Vested Now (USD):</label>
                        <FormattedValue value={withdrawSummary.bankxVested} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                    <li>
                        <label>XSD Vested Now (USD):</label>
                        <FormattedValue value={withdrawSummary.xsdVested} isCurrency isLoading={isWithdrawLoading}/>
                    </li>
                </ul>
            </div>
        </div>
    );

    const rewardsInfo = (
        <>
            <div
                className={tcx('bu-wrap')}
                style={{ padding: '10px 15px 5px' }}
            >
                <b className={Styles.rewardsCTA}>{liquidityValues.rewardsCTACopy}</b>
            </div>
            <div
                className={cx(tcx('si-list'), 'pr-20')}
                style={{ padding: '0 15px 15px' }}
            >
                <ul>
                    <li>
                        <b>Principal and interest in BankX:</b>
                        <FormattedValue value={liquidityValues.roiCopy} isLoading={isLiquidityLoading} isRaw={true}/>
                    </li>
                    <li>
                        <b>Additional reward in XSD:</b>
                        <FormattedValue value={liquidityValues.xsdRoi} isLoading={isLiquidityLoading} isRaw={true}/>
                    </li>
                    <li>
                        <b>Vesting period:</b>
                        <FormattedValue value={liquidityValues.vestingTime} isLoading={isLiquidityLoading} isRaw={true}/>
                    </li>
                    <li>
                        <b>Max liquidity that can be sold (USD):</b>
                        <FormattedValue value={liquidityValues.maxContribution} isCurrency isLoading={isLiquidityLoading}/>
                    </li>
                </ul>
            </div>
        </>
    );

    return (
        <DashboardLayout>
            <div className={cx(tcx('content-wrapper'), tcx('redeem-page'), { 'disable-overlay': isPageDisabled() })}>
                <TopCards />
                <RewardsGroup
                    callToAction={callToAction}
                    rewardsInfo={rewardsInfo}
                    summary={summary}
                    withdraw={withdraw}
                    theme="wide-withdraw"
                />
            </div>
        </DashboardLayout>
    );
}
